import React, { useState, useEffect } from "react";
import clsx from "clsx";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";

import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from "app/utils/Store";
import CommonForm from "app/components/form/CommonForm";
import { Divider, Typography } from "@material-ui/core";
import "../../newLeads/Leads.css";
import { Input } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    width: 520,
    padding: 15,
  },
}));

const FiltersDash = ({
  open,
  onClose,
  onSubmit,
  clients,
  agencies,
  users,
  squares,
  status,
  tags,
  groups,
  justifies,
  vehicles,
  filters,
  setFilters,
  teams,
}) => {
  const monthList = [
    { value: 0, label: "Janeiro" },
    { value: 1, label: "Fevereiro" },
    { value: 2, label: "Março" },
    { value: 3, label: "Abril" },
    { value: 4, label: "Maio" },
    { value: 5, label: "Junho" },
    { value: 6, label: "Julho" },
    { value: 7, label: "Agosto" },
    { value: 8, label: "Setembro" },
    { value: 9, label: "Outubro" },
    { value: 10, label: "Novembro" },
    { value: 11, label: "Dezembro" },
  ];

  const classes = useStyles();
  const fields = [
    {
      col: 12,
      name: "number",
      label: "Nº PI/PP",
      type: "text",
      nullable: true,
    },
    {
      col: 12,
      name: "tags",
      type: "multiselect",
      label: "Etiquetas",
      options: tags.map((k) => ({
        value: k.id_tags,
        label: k.name,
        color: k.color,
      })),
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "fk_id_status",
      label: "Status",
      options: status
        .filter((s) => s.sector == "COMERCIAL")
        .map((st) => {
          return {
            value: st.id_status,
            label: st.name,
            color: "black",
          };
        }),
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "fk_id_substatus",
      label: "Substatus",
      options: status
        .filter((s) => s.sector == "COMERCIAL")
        .map((st) => {
          return {
            value: st.id_status,
            label: st.name,
            color: "black",
          };
        }),
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "fk_id_vehicle",
      label: "Veículo",
      options: vehicles.map((v) => {
        return {
          value: v.id_vehicle,
          label: v.fancy_name,
        };
      }),
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "group",
      label: "Grupo De Mídia",
      options: groups.map((group) => ({
        value: group.id_groups,
        label: group.name,
      })),
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "fk_id_client",
      label: "Cliente",
      options: clients.map((c) => {
        return {
          value: c.id_client,
          label: c.fancy_name,
        };
      }),
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "fk_id_agency",
      label: "Agência",
      options: agencies.map((ag) => {
        return {
          value: ag.id_agency,
          label: ag.fancy_name,
        };
      }),
      nullable: true,
    },
    {
      col: 12,
      type: "text",
      name: "sponsor_vehicle",
      label: "Representante",
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "fk_id_responsable",
      label: "Executivo",
      options: users
        .filter((u) => u.fk_id_role == "executive")
        .map((v) => {
          return {
            value: v.id_user,
            label: v.name,
          };
        }),
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_start",
      label: "Data de Início",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_end",
      label: "Data de Término",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_emission_start",
      label: "Data de Ganho - Início",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_emission_end",
      label: "Data de Ganho - Término",
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "month_sell",
      label: "Mês de venda",
      options: monthList,
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "month_placement",
      label: "Mês de Veiculação",
      options: monthList,
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      name: "fk_id_square",
      label: "Praça de venda",
      options: squares.map((sq) => {
        return {
          value: sq.id_square,
          label: sq.uf,
        };
      }),
      nullable: true,
    },
    {
      col: 12,
      type: "text",
      name: "nf",
      label: "Nº NF",
      nullable: true,
    },
    {
      col: 12,
      type: "multiselect",
      label: "Justificativa",
      name: "fk_id_justify",
      options: justifies
        .filter((j) => j.type_justify == 1)
        .map((justify) => ({ value: justify.id_justify, label: justify.name })),
      nullable: true,
    },
    {
      col: 12,
      type: "text",
      name: "campaign",
      label: "Campanha",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_received_vehicle_start",
      label: "Data de Recebimento do Veículo - Início",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_received_vehicle_end",
      label: "Data de Recebimento do Veículo - Término",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_received_mosaic_start",
      label: "Data de Recebimento do Representante - Início",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_received_mosaic_end",
      label: "Data de Recebimento do Representante - Término",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_received_nf_start",
      label: "Data de Vencimento da NF - Início",
      nullable: true,
    },
    {
      col: 6,
      type: "date",
      name: "dt_received_nf_end",
      label: "Data de Vencimento da NF - Término",
      nullable: true,
    },
  ];

  return (
    <SwipeableDrawer
      classes={{ paper: clsx(classes.root) }}
      open={open}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={onClose}
      disableSwipeToOpen
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          backgroundColor: "white",
        }}
      >
        <Typography className="title pointer"> FILTRAR</Typography>
      </div>

      <CommonForm 
                  onChangeField={(f, v) => {
                    console.log('onchange', f.name)
                    setFilters(prev => ({...prev, [f.name] : v}))
                }}
      values={filters} fields={fields} onSubmit={onSubmit} />
    </SwipeableDrawer>
  );
};

export default FiltersDash;

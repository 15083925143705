import React, { useEffect, useState, useRef } from "react";
import { useResizeDetector } from "react-resize-detector";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import "./Proposal.css";
import "react-toastify/dist/ReactToastify.css";
import { m } from "framer-motion";

const ProposalFinances = ({ proposal, setPage }) => {
  const [valuesForm, setValuesForm] = useState(proposal);
  const { width, height, ref } = useResizeDetector();
  const [taxesValues, setTaxesValues] = useState({})
  const isInitialRender = useRef(true);

  useEffect(() => {
    setTaxesValues({
      iss_vehicle_value : parseFloat(parseFloat(valuesForm.net_value_vehicle || 0) * parseFloat(valuesForm.iss_vehicle || 0) / 100).toFixed(2),
      pis_vehicle_value : parseFloat(parseFloat(valuesForm.net_value_vehicle || 0) * parseFloat(valuesForm.pis_vehicle || 0) / 100).toFixed(2),
      confins_vehicle_value : parseFloat(parseFloat(valuesForm.net_value_vehicle || 0) * parseFloat(valuesForm.cofins_vehicle || 0) / 100).toFixed(2),
      irpj_vehicle_value : parseFloat(parseFloat(valuesForm.net_value_vehicle || 0) * parseFloat(valuesForm.irpj_vehicle || 0) / 100).toFixed(2),
      csll_vehicle_value : parseFloat(parseFloat(valuesForm.net_value_vehicle || 0) * parseFloat(valuesForm.csll_vehicle || 0) / 100).toFixed(2),
    })
  }, [
    valuesForm.net_value_vehicle,
    valuesForm.iss_vehicle,
    valuesForm.pis_vehicle,
    valuesForm.cofins_vehicle,
    valuesForm.irpj_vehicle,
    valuesForm.csll_vehicle
  ])
  useEffect(() => {
    if (isInitialRender.current) {
      if (proposal.taxes_vehicle == undefined || (parseInt(proposal.taxes_vehicle) !== 0 && parseFloat(proposal?.net_value_vehicle) > 0)) {
        return;
      }
    }

    const value = parseFloat(taxesValues.confins_vehicle_value) + parseFloat(taxesValues.csll_vehicle_value) + parseFloat(taxesValues.irpj_vehicle_value)
    + parseFloat(taxesValues.iss_vehicle_value) + parseFloat(taxesValues.pis_vehicle_value)
    setValuesForm(prev => ({...prev,
      taxes_vehicle : value,
    }))
  }, [taxesValues, proposal])

  useEffect(() => {
      setValuesForm(prev => ({...prev,       
        received_value_vehicle : parseFloat(prev.net_value_vehicle - prev.taxes_vehicle).toFixed(2)
}))
  }, [valuesForm.taxes_vehicle, valuesForm.received_value_vehicle])
  const onSubmit = (values) => {
    axios
      .put(
        Constants.APIEndpoints.PROPOSAL +
          "/updatefinances/" +
          proposal.id_proposals,
        valuesForm
      )
      .then((res) => {
        setPage("list");
      });
  };

  return (
    <div ref={ref}>
      <CommonHeader
        title={"Valores Financeiros"}
        onBack={() => setPage("list")}
        width={width}
      />
      <div
        style={{
          marginTop: 120,
        }}
      >
        <CommonForm
          values={valuesForm}
          onChangeField={(f, v) => {
            let values = { ...valuesForm };
            values[f.name] = v;
            if (f.name == "taxes_nf_percent") {
              const taxes_nf = parseFloat(
                valuesForm.comission_value * (v / 100)
              ).toFixed(2);
              values["taxes_nf"] = taxes_nf;
              const received_value = parseFloat(valuesForm.comission_value - valuesForm.incentive - taxes_nf).toFixed(2);
              values["received_value"] = received_value;
            }
            if (f.name == "incentive") {
              const received_value = parseFloat(valuesForm.comission_value - valuesForm.taxes_nf - v).toFixed(2);
              values["received_value"] = received_value;
            }

            if (['iss_vehicle', 'pis_vehicle', 'cofins_vehicle', 'csll_vehicle', 'irpj_vehicle'].includes(f.name)){
              isInitialRender.current = false;
            }

            setValuesForm({ ...values });
          }}
          onSubmit={onSubmit}
          fields={[
            { col: 12, type: "content", content: <h1>Representante</h1> },
            {
              col: 12,
              label: "VL. Comissão Negociado",
              type: "money",
              name: "comission_value_negotiated",
              disabled: true,
            },
            {
              col: 6,
              label: "VL. Comissão",
              type: "money",
              name: "comission_value",
              disabled: true,
            },
            {
              col: 6,
              label: "Incentivo",
              type: "money",
              name: "incentive",
            },
            {
              col: 3,
              label: "%Impostos NF",
              type: "number",
              name: "taxes_nf_percent",
            },

            {
              col: 3,
              label: "VL. Imposto NF",
              type: "number",
              name: "taxes_nf",
              disabled: true,
            },

            {
              col: 3,
              label: "Nº NF",
              type: "text",
              name: "nf_mosaic",
            },
            {
              col: 3,
              label: "Data de Vencimento da NF",
              type: "date",
              name: "nf_due_date",
            },
            {
              col: 6,
              label: "Data de Recebimento",
              type: "date",
              name: "dt_received_mosaic",
            },
            {
              col: 6,
              label: "Valor Recebido",
              type: "money",
              name: "received_value",
            },

            { col: 12, type: "content", content: <h1>Veículo</h1> },
            {
              col: 6,
              label: "Nº NF",
              type: "text",
              name: "nf_vehicle",
            },
            {
              col: 6,
              label: "Data de Emissão",
              type: "date",
              name: "dt_emission_nf_vehicle",
            },
            {
              col: 4,
              label: "Valor Líquido",
              type: "money",
              name: "net_value_vehicle",
            },
            {
              col: 4,
              label: "Impostos do Veículo",
              type: "money",
              name: "taxes_vehicle",
            },
            {
              col: 4,
              label: "Valor Recebido",
              type: "money",
              name: "received_value_vehicle",
              disabled : true,
            },
            {
              col: 2.4,
              label: 'ISS',
              type: 'number',
              name: 'iss_vehicle'
            },
            {
              col: 2.4,
              label: 'PIS',
              type: 'number',
              name: 'pis_vehicle'
            },{
              col: 2.4,
              label: 'COFINS',
              type: 'number',
              name: 'cofins_vehicle'
            },{
              col: 2.4,
              label: 'IRPJ',
              type: 'number',
              name: 'irpj_vehicle'
            },{
              col: 2.4,
              label: 'CSLL',
              type: 'number',
              name: 'csll_vehicle'
            },
            {
              col: 12,
              type: 'content',
              content: (
                <CommonForm 
                noPadding={true}
                values={taxesValues}

                fields = {[
                  {
                    col: 2.4,
                    type: 'number',
                    name: 'iss_vehicle_value',
                    disabled: true,
                  },
                  {
                    col: 2.4,
                    type: 'number',
                    name: 'pis_vehicle_value',
                    disabled: true,
                  },{
                    col: 2.4,
                    type: 'number',
                    name: 'confins_vehicle_value',
                    disabled : true
                  },{
                    col: 2.4,
                    type: 'number',
                    name: 'irpj_vehicle_value',
                    disabled : true
                  },{
                    col: 2.4,
                    type: 'number',
                    name: 'csll_vehicle_value',
                    disabled: true
                  },
                ]}
                />
              )
            },
            {
              col: 3,
              label: "Data de Recebimento do Veículo",
              type: "date",
              name: "dt_received_vehicle",
            },
            { col: 12, type: "content", content: <h1>COMISSÕES</h1> },
            {
              col: 12,
              label: "Data de Pagamento de Comissão de Executivos",
              type: "date",
              name: "dt_received_comission_executive",
            },
            {
              col: 12,
              label: "Data de Pagamento de Comissão de Gerentes",
              type: "date",
              name: "dt_received_comission_managers",
            },
            {
              col: 12,
              label: "Data de Pagamento de Comissão de Diretores",
              type: "date",
              name: "dt_received_comission_directors",
            },
            { col: 12, type: "content", content: <h1>NEGOCIAÇÕES</h1> },

  
            {
              col: 12,
              name: "project",
              label: "Projeto",
              type: "checkbox",
            },
            {
              col: 12,
              name: "incentive_paid",
              label: "Incentivo Pago",
              type: "checkbox",
            },
            {
              col:12,
              name: "incentive_nf",
              label: "NF Incentivo",
              type: "text",
            },
            { col: 12, type: "content", content: <h1>ANOTAÇÕES GERAIS</h1> },
            {
              col: 12,
              label: "Observações",
              type: "textarea",
              name: "observation_finances",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ProposalFinances;

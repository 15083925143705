import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import moment from "moment-timezone";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import "./ProposalView.css";
const ProposalFinancesView = ({ id_proposals }) => {
  const [proposal, setProposal] = useState([]);

  useEffect(() => {
    axios
      .get(
        Constants.APIEndpoints.PROPOSAL + "/getproposalfinances/" + id_proposals
      )
      .then((res) => {
        setProposal(res.data);
      });
  }, []);

  return (
    <div>
      <div style={{ marginTop: 60 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1} className="gridContainer" >
          <h1>REPRESENTANTE</h1>
          <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>VL. Comissão Negociado</label>
                <p className="value">
                  {parseFloat(
                    proposal.comission_value_negotiated || 0
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>
            <Grid item xs={6} className="gridItem">
              <div className="cardProposal">
                <label>VL. Comissão</label>
                <p className="value">
                  {parseFloat(proposal.comission_value || 0).toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                  )}
                </p>
              </div>
            </Grid>
            <Grid item xs={6} className="gridItem">
              <div className="cardProposal">
                <label>Incentivo</label>
                <p className="value">
                  {parseFloat(proposal.incentive || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>
            <Grid item xs={3} className="gridItem">
              <div className="cardProposal">
                <label>%Impostos NF</label>
                <p className="value">
                  {parseFloat(proposal.taxes_nf_percent || 0)}%
                </p>
              </div>
            </Grid>
            <Grid item xs={3} className="gridItem">
              <div className="cardProposal">
                <label>VL. Imposto NF</label>
                <p className="value">
                  {parseFloat(proposal.taxes_nf || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>
            <Grid item xs={3} className="gridItem">
              <div className="cardProposal">
                <label>Nº NF</label>
                <p className="value">{proposal.nf_mosaic}</p>
              </div>
            </Grid>
            <Grid item xs={3} className="gridItem">
              <div className="cardProposal">
                <label>Data de Vencimento da NF</label>
                <p className="value">
                  {proposal.nf_due_date
                    ? moment(proposal.nf_due_date).format("DD/MM/YYYY")
                    : ""}
                </p>
              </div>
            </Grid>
            <Grid item xs={6} className="gridItem">
              <div className="cardProposal">
                <label>Data de Recebimento</label>
                <p className="value">
                  {proposal.dt_received_mosaic
                    ? moment(proposal.dt_received_mosaic).format("DD/MM/YYYY")
                    : ""}
                </p>
              </div>
            </Grid>
            <Grid item xs={6} className="gridItem">
              <div className="cardProposal">
                <label>Valor Recebido</label>
                <p className="value">
                  {parseFloat(proposal.received_value || 0).toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                  )}
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={1} className="gridContainer" >
          <h1>VEÍCULO</h1>

          <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>Nº NF</label>
                <p className="value">{proposal.nf_vehicle}</p>
              </div>
            </Grid>
            <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>Data de Emissão</label>
                <p className="value">
                  {proposal.dt_emission_nf_vehicle
                    ? moment(proposal.dt_emission_nf_vehicle).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </p>
              </div>
            </Grid>
            <Grid item xs={4} className="gridItem">
              <div className="cardProposal">
                <label>Valor Líquido</label>
                <p className="value">
                  {parseFloat(proposal.net_value_vehicle || 0).toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                  )}
                </p>
              </div>
            </Grid>
            <Grid item xs={4} className="gridItem">
              <div className="cardProposal">
                <label>Impostos do Veículo</label>
                <p className="value">
                  {parseFloat(proposal.taxes_vehicle || 0).toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                  )}
                </p>
              </div>
            </Grid>
            <Grid item xs={4} className="gridItem">
              <div className="cardProposal">
                <label>Valor Recebido</label>
                <p className="value">
                  {parseFloat(
                    proposal.received_value_vehicle || 0
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>
            <Grid item xs={2} className="gridItem">
              <div className="cardProposal">
                <label>ISS</label>
                <p className="value">
                  {parseFloat(proposal.iss_vehicle || 0)}%
                </p>
              </div>
            </Grid>

            <Grid item xs={2} className="gridItem">
              <div className="cardProposal">
                <label>PIS</label>
                <p className="value">
                  {parseFloat(proposal.pis_vehicle || 0)}%
                </p>
              </div>
            </Grid>
            <Grid item xs={3} className="gridItem">
              <div className="cardProposal">
                <label>COFINS</label>
                <p className="value">
                  {parseFloat(proposal.cofins_vehicle || 0)}%
                </p>
              </div>
            </Grid>
            <Grid item xs={2} className="gridItem">
              <div className="cardProposal">
                <label>IRPJ</label>
                <p className="value">
                  {parseFloat(proposal.irpj_vehicle || 0)}%
                </p>
              </div>
            </Grid>
            <Grid item xs={3} className="gridItem">
              <div className="cardProposal">
                <label>CSLL</label>
                <p className="value">
                  {parseFloat(proposal.csll_vehicle || 0)}%
                </p>
              </div>
            </Grid>
            <Grid item xs={2} className="gridItem">
              <div className="cardProposal">
                <p className="value">
                  {parseFloat(
                    (proposal.iss_vehicle * proposal.net_value_vehicle) / 100
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>

            <Grid item xs={2} className="gridItem">
              <div className="cardProposal">
                <p className="value">
                  {parseFloat(
                    (proposal.pis_vehicle * proposal.net_value_vehicle) / 100
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>
            <Grid item xs={3} className="gridItem">
              <div className="cardProposal">
                <p className="value">
                  {parseFloat(
                    (proposal.cofins_vehicle * proposal.net_value_vehicle) / 100
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>
            <Grid item xs={2} className="gridItem">
              <div className="cardProposal">
                <p className="value">
                  {parseFloat(
                    (proposal.irpj_vehicle * proposal.net_value_vehicle) / 100
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>
            <Grid item xs={3} className="gridItem">
              <div className="cardProposal">
                <p className="value">
                  {parseFloat(
                    (proposal.csll_vehicle * proposal.net_value_vehicle) / 100
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </Grid>

            <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>Data de Recebimento do Veículo</label>
                <p className="value">
                  {proposal.dt_received_vehicle
                    ? moment(proposal.dt_received_vehicle).format("DD/MM/YYYY")
                    : ""}
                </p>
              </div>
            </Grid>
            </Grid>
            <Grid container spacing={1} className="gridContainer" >
            <h1>COMISSÕES</h1>

            <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>Data de Pagamento de Comissão de Executivos</label>
                <p className="value">
                  {proposal.dt_received_comission_executive
                    ? moment(proposal.dt_received_comission_executive).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>Data de Pagamento de Comissão de Gerentes</label>
                <p className="value">
                  {proposal.dt_received_comission_managers
                    ? moment(proposal.dt_received_comission_managers).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>Data de Pagamento de Comissão de Diretores</label>
                <p className="value">
                  {proposal.dt_received_comission_directors
                    ? moment(proposal.dt_received_comission_directors).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </p>
              </div>
            </Grid>
            </Grid>
            <Grid container spacing={1} className="gridContainer" >

            <h1>NEGOCIAÇÕES</h1>

            <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>Projeto</label>
                <p className="value">{proposal.project ? "Sim" : "Não"}</p>
              </div>
            </Grid>
            <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>Incentivo Pago</label>
                <p className="value">
                  {proposal.incentive_paid ? "Sim" : "Não"}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} className="gridItem">
              <div className="cardProposal">
                <label>NF Incentivo</label>
                <p className="value">{proposal.incentive_nf}</p>
              </div>
            </Grid>
            </Grid>
            <Grid container spacing={1} className="gridContainer" >
            <h1>ANOTAÇÕES GERAIS</h1>

            <Grid item xs={12}>
              <div className="cardProposal">
                <label>Observações</label>
                <p
                  className="value"
                  dangerouslySetInnerHTML={{
                    __html: proposal.observation_finances,
                  }}
                ></p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ProposalFinancesView;

import { Typography } from "@material-ui/core";
import Field from "app/main/configuration/formulas/Field";
import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

let delayTimer;

export default function TextEditorQuill({ onBlur, disabled, value, onChange, f }) {
    const editorJodit = React.useRef(null);
    let [value_, setValue_] = useState('');
    let [data, setData] = useState('');
    const [isTagSelectorVisible, setTagSelectorVisible] = useState(false);
    const [selectedTag, setSelectedTag] = useState('');

    const tagStyle = {
        backgroundColor: '#f0f0f0',
        padding: '5px',
        border: '1px solid #ccc',
        margin: '5px',
        cursor: 'pointer',
        borderRadius: '10px',
        fontSize: '12px',
    };

    const tags = {
        vehicle : 'veículo',
        net_value_approved : 'valor líquido',
        gross_value_approved:  'valor bruto',
        comission_value : 'comissão',
        sponsor: 'representante',
        groupname : 'grupo de mídia',
        client : 'cliente',
        agency : 'agência',
        received_value : 'valor recebido'
      }

    useEffect(() => {
        setValue_(value);
    }, [value])

    const handleKeyPress = (event) => {
        if (event.key === '$') {
          setTagSelectorVisible(true);
        }
        else {
          if (isTagSelectorVisible) setTagSelectorVisible(false);
        }
      };

      const handleTagClick = (tag) => {
        if (editorJodit.current) {
          const quill = editorJodit.current.getEditor();
          const range = quill.getSelection();
          if (range) {
            quill.insertText(range.index, tag);
            quill.setSelection(range.index + tag.length);
          }
        }
    
        setSelectedTag(tag);
        setTagSelectorVisible(false);
      };

    return (
        <div>
          {f.helperText && (
            <div dangerouslySetInnerHTML={{__html : f.helperText}} />
          )}
            <ReactQuill
            ref={editorJodit}
            value={value || '<span></span>'}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            readOnly={disabled}
            placeholder={f.placeholder}
            
            style={{ backgroundColor: 'white' }}

        />
        {isTagSelectorVisible && !f.onlyReturn &&  (
        <div className="tag-selector">
          {Object.keys(tags).map((tag) => (
            <button
              key={tag}
              className="tag"
              style={tagStyle}
              onClick={() => handleTagClick(tag)}
            >
              {tags[tag]}
            </button>
          ))}
        </div>
      )}

      {isTagSelectorVisible && f.onlyReturn &&  (
        <div className="tag-selector">
          {Object.keys({return : 'retorno'}).map((tag) => (
            <button
              key={tag}
              className="tag"
              style={tagStyle}
              onClick={() => handleTagClick(tag)}
            >
              retorno
            </button>
          ))}
        </div>
      )}
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Divider } from '@material-ui/core';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import moment from 'moment-timezone'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';
import CommonDialog from 'app/components/dialog/CommonDialog';
import CommonTable from 'app/components/table/CommonTable';
import { Tooltip } from "@material-ui/core";
import ConfirmDialog from 'app/components/dialog/ConfirmDialog';
import { motion } from "framer-motion";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    width: 520,
    padding: 15
  },
}));


function OwnDashSidebar
  ({
    open,
    onClose,
    users,
    dashes,
    groups,
    getDashes,
    selectedDash,
    idDashSelected,
    setIdDashSelected,
    loadDash,
    setSelectedDash,
    teams
  }) {
  const classes = useStyles();
  const [openCreate, setOpenCreate] = useState(false)
  const [openManagerGroups, setOpenManagerGroups] = useState(false)
  const [allGroups, setAllGroups] = useState([])
  const [openAddTeams, setOpenAddTeams] = useState(false)
  const [openAddUsers, setOpenAddUsers] = useState(false)
  const [openAddGroups, setOpenAddGroups] = useState(false)
  const [openAddDash, setOpenAddDash] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState([])
  const [openDeleteGroup, setOpenDeleteGroup] = useState(false)
  const [openCreateGroups, setOpenCreateGroups] = useState(false)
  const [expandedIds, setExpandedIds] = useState([]);
  const [itensGroup, setItensGroup] = useState([])

  const toggleExpand = (id) => {
    setExpandedIds((prev) =>
      prev.includes(id) ? prev.filter((expandedId) => expandedId !== id) : [...prev, id]
    );

    if (!expandedIds.includes(id)) {
      getItensGroup(id); // Adiciona itens do grupo ou subgrupo
    }
  };

  const saveDash = () => {
    axios.post(Constants.APIEndpoints.DASHBOARD + (selectedDash.id_dash ? '/updatedash' : '/savedash'), selectedDash).then(res => {
      setOpenCreate(false)
      getDashes()
    })
  }


  const getItensGroup = (id) => {
    axios
      .get(Constants.APIEndpoints.DASHBOARD + '/group/getitens/' + id)
      .then((res) => {
        setItensGroup(prev => ({ ...prev, ...res.data }))
      })

  };


  const editDash = (dash) => {
    setIdDashSelected(dash.id_dash)
    if (dash.dt_start) {
      dash.dt_start = moment(dash.dt_start).format('YYYY-MM-DD')
    }
    if (dash.dt_end) {
      dash.dt_end = moment(dash.dt_end).format('YYYY-MM-DD')
    }
    setSelectedDash(dash)
    setOpenCreate(true)
  }

  const deleteDash = (dash) => {
    axios.post(Constants.APIEndpoints.DASHBOARD + '/deletedash', dash).then(res => {
      getDashes()
    })
  }

  const copyDash = (dash) => {
    axios.post(Constants.APIEndpoints.DASHBOARD + '/copydash', dash).then(res => {
      getDashes()
    })
  }

  const onAddGroups = () => {
    setSelectedGroup({})
    setOpenCreateGroups(true)

  }

  const getGroups = () => {
    axios.get(Constants.APIEndpoints.DASHBOARD + '/group/getgroupdashes').then(res => {
      setAllGroups(res.data)
    })
  }

  const saveGroup = (values) => {
    const url = selectedGroup.id_group_dashes ? '/group/updategroupdash' : '/group/creategroupdash'
    axios.post(Constants.APIEndpoints.DASHBOARD + url, values).then(res => {
      getGroups()
      setOpenCreateGroups(false)
    })
  }

  const saveSubgroups = (values) => {
    axios.post(Constants.APIEndpoints.DASHBOARD + '/group/addsubgroup/' + selectedGroup.id_group_dashes, values).then(res => {
      getGroups()
      setOpenAddGroups(false)
    })
  }

  const saveTeams = (values) => {
    axios.post(Constants.APIEndpoints.DASHBOARD + '/group/addteam/' + selectedGroup.id_group_dashes, values).then(res => {
      getGroups()
      setOpenAddTeams(false)
    })
  }

  const saveUsers = (values) => {
    axios.post(Constants.APIEndpoints.DASHBOARD + '/group/adduser/' + selectedGroup.id_group_dashes, values).then(res => {
      getGroups()
      setOpenAddUsers(false)
    })
  }

  const saveDashes = (values) => {
    axios.post(Constants.APIEndpoints.DASHBOARD + '/group/adddash/' + selectedGroup.id_group_dashes, values).then(res => {
      getGroups()
      setOpenAddDash(false)
    })
  }

  const deleteGroup = () => {
    axios.delete(Constants.APIEndpoints.DASHBOARD + '/group/delete/' + selectedGroup.id_group_dashes).then(res => {
      getGroups()
      setOpenDeleteGroup(false)
    })
  }


  const buildItems = (item) => {
    if (item.type == 'dash') {
      return (
        <div className="funnelDiv"
          style={{
            backgroundColor: item.id_dash == idDashSelected ? '#1b2330' : 'white',
            color: item.id_dash == idDashSelected ? 'white' : 'black',
            marginTop: 10
          }}
        >
          <Typography
            style={{ width: '55%' }}
            onClick={() => loadDash(item)}
          >{item.name}</Typography>
          <div>
            <IconButton>
              <Icon
                onClick={() => copyDash(item)}
                style={{ color: item.id_dash == idDashSelected ? 'white' : 'black' }}
              >
                copy
              </Icon>
            </IconButton>
            <IconButton>
              <Icon
                onClick={() => editDash(item)}
                style={{ color: item.id_dash == idDashSelected ? 'white' : 'black' }}
              >
                edit
              </Icon>
            </IconButton>
            <IconButton>
              <Icon
                style={{ color: item.id_dash == idDashSelected ? 'white' : 'black' }}
                onClick={() => deleteDash(item)}
              >
                delete
              </Icon>
            </IconButton>
          </div>
        </div>
      )
    }

    return (
        <Accordion
          key={item.id_group_dashes}
          expanded={expandedIds.includes(item.id_group_dashes)}
          onChange={() => toggleExpand(item.id_group_dashes)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {item.name}
          </AccordionSummary>
          <AccordionDetails>
            {(itensGroup[item.id_group_dashes] || []).map(item => buildItems(item))}
          </AccordionDetails>
        </Accordion>
    );
  }

  return (

    <SwipeableDrawer
      classes={{ paper: clsx(classes.root) }}
      open={open}
      anchor="right"
      onOpen={(ev) => { }}
      onClose={onClose}
      disableSwipeToOpen

    >
      <div className="flex flex-col space-y-32">
        <Typography
          style={{ textAlign: 'center', fontSize: 20, fontWeight: 500 }}>Suas Dashboards</Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {access.owndash_create_newdash ? (
            <button className='buttonChangeViewMode'
              onClick={() => {
                setOpenCreate(true)
                setSelectedDash({})
              }}
              style={{
                color: 'white', backgroundColor: '#252f3e', maxWidth: 150
                , borderRadius: 30, margin: 0, marginTop: 10
              }}>Criar Nova
            </button>
          ) : null}
          {access.owndash_manager_groups ? (
            <button className='buttonChangeViewMode'
              onClick={() => {
                getGroups()
                setOpenManagerGroups(true)
                setSelectedDash({})
              }}
              style={{
                color: 'white', backgroundColor: '#252f3e', maxWidth: 150
                , borderRadius: 30, margin: 0, marginTop: 10
              }}>Gerenciar Grupos
            </button>
          ) : null}

        </div>
        <Divider
          style={{ marginTop: 20 }}
        />
        {openCreate && (
          <CommonForm
            values={selectedDash}
            onChangeField={(f, v) => {
              const newDash = { ...selectedDash }
              newDash[f.name] = v
              setSelectedDash(newDash)
            }}
            fields={[
              {
                col: 12,
                name: 'name',
                label: 'Nome da Dash',
                type: 'text',
                required: true
              },
              {
                col: 12,
                type: 'checkbox',
                label: 'Usar filtro de data flexível',
                name: 'date_flexible'
              },
              {
                col: 12,
                type: 'select',
                label: 'Período',
                name: 'period',
                visible: selectedDash?.date_flexible == true,
                options: [
                  { value: 1, label: 'Semana atual' },
                  { value: 2, label: 'Mês Atual' },
                  { value: 3, label: 'Bimestre Atual' },
                  { value: 4, label: 'Ano Atual' },
                  { value: 5, label: 'Personalizado' },
                ]
              },
              {
                col: 6,
                type: "date",
                name: "dt_start",
                label: "De",
                visible: selectedDash?.period == 5
              },
              {
                col: 6,
                type: "date",
                name: "dt_end",
                label: "Até",
                visible: selectedDash?.period == 5
              },
              {
                col: 12,
                name: 'users',
                label: 'Usuários',
                type: 'multiselect',
                options: users.map(user => ({ value: user.id_user, label: user.name }))
              }

            ]}
            onSubmit={saveDash}
          />
        )}

        {openDeleteGroup ? (
          <ConfirmDialog title="Deseja deletar esse Grupo?" cancel={() => setOpenDeleteGroup(false)} confirm={deleteGroup} />
        ) : null}



        {(groups || []).map((group) => (
          <Accordion
            key={group.id_group_dashes}
            expanded={expandedIds.includes(group.id_group_dashes)}
            onChange={() => toggleExpand(group.id_group_dashes)}
            >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {group.name}
            </AccordionSummary>
            <AccordionDetails>
              {(itensGroup[group.id_group_dashes] || []).map(item => buildItems(item))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>


      <CommonDialog
        open={openManagerGroups}
        onClose={() => {
          setOpenManagerGroups(false);
          getDashes()
        }}
        title={"Gerenciar Grupos"}
        fullWidth={true}
        width={"lg"}
      >
        <CommonTable
          miniVersion={true}
          newText="Adicionar Novo Grupo"
          onAdd={access.proposal_create ? onAddGroups : null}
          columns={[{
            Header: "Nome",
            accessor: "name",
          },
          {
            Header: "Ordem",
            accessor: "order_group",
          },

          {
            id: 'action',
            sortable: false,
            Cell: ({ row }) => (
              <div className='flex items-center'>
                <Tooltip title={<h5>Times Que Visualizam</h5>} placement="right">
                  <IconButton
                    onClick={(ev) => {
                      setSelectedGroup(row.original)
                      setOpenAddTeams(true)
                    }}
                  >
                    <Icon>people</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title={<h5>Usuários que visualizam</h5>} placement="right">
                  <IconButton
                    onClick={(ev) => {
                      setSelectedGroup(row.original)
                      setOpenAddUsers(true);
                    }}
                  >
                    <Icon>person</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title={<h5>Subgrupos</h5>} placement="right">
                  <IconButton
                    onClick={(ev) => {
                      setSelectedGroup(row.original)
                      setOpenAddGroups(true);;
                    }}
                  >
                    <Icon>people</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title={<h5>Dashes</h5>} placement="right">
                  <IconButton
                    onClick={(ev) => {
                      setSelectedGroup(row.original)
                      setOpenAddDash(true)
                    }}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title={<h5>Editar</h5>} placement="right">
                  <IconButton
                    onClick={(ev) => {
                      row.original.order_group = row.original.order_group.toString()
                      setSelectedGroup(row.original)
                      setOpenCreateGroups(true)
                    }}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title={<h5>Excluir</h5>} placement="right">
                  <IconButton
                    onClick={(ev) => {
                      setSelectedGroup(row.original)
                      setOpenDeleteGroup(true)
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Tooltip>
              </div>
            )
          }
          ]}
          data={allGroups}
        />
      </CommonDialog>

      <CommonDialog
        open={openCreateGroups}
        onClose={() => {
          setOpenCreateGroups(false);
        }}
        title={"Novo Grupo"}
        fullWidth={true}
        width={"lg"}
      >
        <CommonForm
          fields={[
            {
              col: 6,
              type: 'text',
              label: 'Nome',
              name: 'name'
            },
            {
              col: 6,
              type: 'number',
              label: 'Ordem',
              name: 'order_group'
            }
          ]}
          values={selectedGroup}
          onChangeField={(f, v) => setSelectedGroup(prev => ({ ...prev, [f.name]: v }))}
          onSubmit={saveGroup}
        />
      </CommonDialog>

      <CommonDialog
        open={openAddGroups}
        onClose={() => {
          setOpenAddGroups(false);
        }}
        title={"Adicionar subgrupos"}
        fullWidth={true}
        width={"md"}
      >
        <CommonForm
          fields={[
            {
              col: 12,
              label: 'Grupos',
              name: 'subgroups',
              type: 'listCheckbox',
              options: (allGroups || []).map(g => ({ value: g.id_group_dashes, label: g.name }))
            }
          ]}
          values={selectedGroup}
          onSubmit={saveSubgroups}
        />
      </CommonDialog>

      <CommonDialog
        open={openAddUsers}
        onClose={() => {
          setOpenAddUsers(false);
        }}
        title={"Adicionar Usuários"}
        fullWidth={true}
        width={"md"}
      >
        <CommonForm
          fields={[
            {
              col: 12,
              label: 'Usuários',
              name: 'users',
              type: 'listCheckbox',
              options: (users || []).map(g => ({ value: g.id_user, label: g.name }))
            }
          ]}
          values={selectedGroup}
          onSubmit={saveUsers}
        />
      </CommonDialog>

      <CommonDialog
        open={openAddDash}
        onClose={() => {
          setOpenAddDash(false);
        }}
        title={"Adicionar Dashes"}
        fullWidth={true}
        width={"md"}
      >
        <CommonForm
          fields={[
            {
              col: 12,
              label: 'Dashes',
              name: 'dashes',
              type: 'listCheckbox',
              options: (dashes || []).map(g => ({ value: g.id_dash, label: g.name }))
            }
          ]}
          values={selectedGroup}
          onSubmit={saveDashes}
        />
      </CommonDialog>


      <CommonDialog
        open={openAddTeams}
        onClose={() => {
          setOpenAddTeams(false);
        }}
        title={"Adicionar Times"}
        fullWidth={true}
        width={"md"}
      >
        <CommonForm
          fields={[
            {
              col: 12,
              label: 'Times',
              name: 'teams',
              type: 'listCheckbox',
              options: (teams || []).map(g => ({ value: g.id_team, label: g.name }))
            }
          ]}
          values={selectedGroup}
          onSubmit={saveTeams}
        />
      </CommonDialog>


    </SwipeableDrawer >
  );
}

export default OwnDashSidebar
  ;
